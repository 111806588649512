





























































































































































.containerBottom {
  font-size: 12px;
  background: #333333;
  color: #ffffff;
  .center-layout {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .topFrame {
    display: flex;
    justify-content: space-around;
    .itemLink {
      width: 60%;
      display: flex;
      justify-content: space-between;
      ul {
        display: inline-block;
        li,
        a {
          font-size: 12px;
          opacity: 0.8;
          line-height: 3rem;
          color: #d1cfcf;
          a {
            color: #fff;
            text-decoration: none;
          }
        }
        li:first-child {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
          opacity: 1;
          color: #fff;
        }
        .pointer {
          cursor: pointer;
          & :hover {
            color: #d1cfcf !important;
          }
        }
      }
    }
    .itemInfo {
      text-align: right;
      .phoneLine {
        font-size: 1.4rem;
        font-weight: 500;
        b {
          font-size: 2rem;
          font-weight: normal;
          margin-left: 1.4rem;
        }
      }
      .phoneMonday {
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 0.5rem;
      }
      ul {
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        padding: 5px 0 0 0;
        // display: flex;
        li {
          display: inline-block;
          margin: 1rem 0 0 0;
          img {
            height: 7.83rem;
            width: 7.83rem;
            margin-bottom: 1rem;
          }
          span {
            display: block;
            width: 8rem;
            text-align: center;
            font-size: 1.33rem;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }

  .footerAbout {
    margin: 10px 5px 0px 5px;
    padding-bottom: 13px;
    background-color: #282828;
    & > div {
      margin: 10px 0;
    }
    .friens_link {
      .center-layout;
      div {
        font-size: 1.3rem;
        margin: 0 30px;
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }
    .foot_nav {
      ul {
        li {
          font-size: 1.3rem;
          float: left;
          a {
            text-decoration: none;
            color: #ffffff;
          }
          a::after {
            content: "|";
            margin: 0 1rem;
          }
        }
        li:last-child a::after {
          content: "";
        }
      }
    }
    .epi_part {
      font-size: 1.3rem;
      text-align: center;
    }
    ._mark {
      .center-layout;
      ul {
        margin: 0;
        li {
          font-size: 1.3rem;
          .center-layout;
          a {
            text-decoration: none;
            color: #ffffff;
          }
        }
        .record::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../assets/recordIcon.png) no-repeat center center;
          margin-right: 10px;
          position: relative;
          top: -4px;
          float: left;
        }
      }
    }
  }
}
