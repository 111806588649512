.containerBottom {
  font-size: 12px;
  background: #333333;
  color: #ffffff;
}
.containerBottom .center-layout {
  width: 100%;
  justify-content: center;
  display: flex;
}
.containerBottom .topFrame {
  display: flex;
  justify-content: space-around;
}
.containerBottom .topFrame .itemLink {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.containerBottom .topFrame .itemLink ul {
  display: inline-block;
}
.containerBottom .topFrame .itemLink ul li,
.containerBottom .topFrame .itemLink ul a {
  font-size: 12px;
  opacity: 0.8;
  line-height: 3rem;
  color: #d1cfcf;
}
.containerBottom .topFrame .itemLink ul li a,
.containerBottom .topFrame .itemLink ul a a {
  color: #fff;
  text-decoration: none;
}
.containerBottom .topFrame .itemLink ul li:first-child {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  opacity: 1;
  color: #fff;
}
.containerBottom .topFrame .itemLink ul .pointer {
  cursor: pointer;
}
.containerBottom .topFrame .itemLink ul .pointer :hover {
  color: #d1cfcf !important;
}
.containerBottom .topFrame .itemInfo {
  text-align: right;
}
.containerBottom .topFrame .itemInfo .phoneLine {
  font-size: 1.4rem;
  font-weight: 500;
}
.containerBottom .topFrame .itemInfo .phoneLine b {
  font-size: 2rem;
  font-weight: normal;
  margin-left: 1.4rem;
}
.containerBottom .topFrame .itemInfo .phoneMonday {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.containerBottom .topFrame .itemInfo ul {
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  padding: 5px 0 0 0;
}
.containerBottom .topFrame .itemInfo ul li {
  display: inline-block;
  margin: 1rem 0 0 0;
}
.containerBottom .topFrame .itemInfo ul li img {
  height: 7.83rem;
  width: 7.83rem;
  margin-bottom: 1rem;
}
.containerBottom .topFrame .itemInfo ul li span {
  display: block;
  width: 8rem;
  text-align: center;
  font-size: 1.33rem;
  font-weight: 400;
  color: #ffffff;
}
.containerBottom .footerAbout {
  margin: 10px 5px 0px 5px;
  padding-bottom: 13px;
  background-color: #282828;
}
.containerBottom .footerAbout > div {
  margin: 10px 0;
}
.containerBottom .footerAbout .friens_link {
  width: 100%;
  justify-content: center;
  display: flex;
}
.containerBottom .footerAbout .friens_link div {
  font-size: 1.3rem;
  margin: 0 30px;
}
.containerBottom .footerAbout .friens_link div a {
  text-decoration: none;
  color: #ffffff;
}
.containerBottom .footerAbout .foot_nav ul li {
  font-size: 1.3rem;
  float: left;
}
.containerBottom .footerAbout .foot_nav ul li a {
  text-decoration: none;
  color: #ffffff;
}
.containerBottom .footerAbout .foot_nav ul li a::after {
  content: "|";
  margin: 0 1rem;
}
.containerBottom .footerAbout .foot_nav ul li:last-child a::after {
  content: "";
}
.containerBottom .footerAbout .epi_part {
  font-size: 1.3rem;
  text-align: center;
}
.containerBottom .footerAbout ._mark {
  width: 100%;
  justify-content: center;
  display: flex;
}
.containerBottom .footerAbout ._mark ul {
  margin: 0;
}
.containerBottom .footerAbout ._mark ul li {
  font-size: 1.3rem;
  width: 100%;
  justify-content: center;
  display: flex;
}
.containerBottom .footerAbout ._mark ul li a {
  text-decoration: none;
  color: #ffffff;
}
.containerBottom .footerAbout ._mark ul .record::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../assets/recordIcon.png) no-repeat center center;
  margin-right: 10px;
  position: relative;
  top: -4px;
  float: left;
}
